// @ts-nocheck
import React, { useEffect, useState } from "react";
import LeftProduct from "../component/Product/Build/LeftProduct";
import ProductPopupComponent from "../component/Product/Build/ProductPopupComponent";
import RightProducts from "../component/Product/Build/RightProducts";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PresetProductCart from "../component/Product/PresetProductCart";
import PresetFullProduct from "../component/Product/Preset/PresetFullProduct";
import { saveLoader } from "../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import {
  useAddToCartMutation,
  useCartListMutation,
  useCategoryListMutation,
  useDeleteCartMutation,
  usePackUpdateApiMutation,
  useProductListMutation,
  useProductPriceListMutation,
  useProductViewMutation,
  useUpdateToCartMutation,
} from "../redux/api/api";
import { useNavigate } from "react-router-dom";
import PresetPopupComponent from "../component/Product/Preset/PresetPopupComponent";

const PresetPackProductScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tick, setTick] = useState([]);
  const Token = localStorage.getItem("token");
  const cartProduct = JSON.parse(localStorage.getItem("cart"));
  const [dataBase, setDataBase] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [viewId, setViewId] = useState("");
  const [viewList, setViewList] = useState([]);
  const [imagesid, setImagesId] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [packsPrice, setPacksPrice] = useState("");
  const [count, setCount] = useState(1);
  const [colorcount, setColorCount] = useState(1);
  const [cortList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState({});
  const [priceLists, setPriceLists] = useState({});
  const [bigImg, setBigImg] = useState(0);
  const [style, setStyle] = useState("");
  const [size, setSize] = useState("");
  const [material, setMaterial] = useState("");
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [skuCodeList, setCodeList] = useState([]);
  const [skuCode, setCode] = useState("");
  const [pack, setPack] = useState(25);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPerPrice, setTotalPerPrice] = useState("");
  const [ListPrice, setListPrice] = useState({});
  const [cartData, setCartData] = useState({});
  const [userCartID, setUserCartID] = useState("");
  const [cartBtn, setCartBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [showoption, setShowOption] = useState(true);
  const [presetData, setPresetData] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  const [no_pack, setNo_of_pack] = useState(25);

  const [noOfPack, setNoOfPack] = useState("");
  const [noOfPackErr, setNoOfPackErr] = useState(false);

  //RTK
  const [CategoryListApi] = useCategoryListMutation();
  const [ProductListApi] = useProductListMutation();
  const [productViewApi] = useProductViewMutation();
  const [productPriceApi] = useProductPriceListMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [cartListApi] = useCartListMutation();
  const [editToCartApi] = useUpdateToCartMutation();
  const [deleteApi] = useDeleteCartMutation();
  const [packUpdateApi] = usePackUpdateApiMutation();

  const showPopupHander = () => {
    setShowPopup(!showPopup);
  };

  // TICK PRODUCT
  const onClickProduct = (product) => {
    const formData = new FormData();
    setViewId(product);
    formData?.append("product_ids", product);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: product })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setSelectedColorId(res?.product?.colors?.[0]?.name);
        setSelectColorId(res?.product?.colors?.[0]?.id);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let final = {
          ...obj,
          [res?.product?.id]: {
            ...object,
            for_price: +res?.product?.for_25,
            pack: 25,
            count_value: 1,
          },
        };

        // setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const list = [];
        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            list.push({ img: color?.image_url });
          });
          item?.colors?.map((hex) => {
            list?.push({ ...hex, hex_code: hex?.hex_code });
          });

          image?.push({
            ...item,
            images: list,
          });
        });
        // console.log(image);
        // setStyle(ima)
        setViewList(image);

        let no_of_packed = 25;

        if (+no_pack >= 1 && +no_pack <= 25) {
          no_of_packed = 25;
        } else if (+no_pack > 25 && +no_pack <= 50) {
          no_of_packed = 50;
        } else if (+no_pack > 50 && +no_pack <= 100) {
          no_of_packed = 100;
        } else if (+no_pack > 100 && +no_pack <= 150) {
          no_of_packed = 150;
        } else if (+no_pack > 150 && +no_pack <= 250) {
          no_of_packed = 250;
        } else if (+no_pack > 250 && +no_pack <= 500) {
          no_of_packed = 500;
        } else if (+no_pack > 500 && +no_pack <= 1000) {
          no_of_packed = 1000;
        } else if (+no_pack > 1000) {
          no_of_packed = 1000;
        }

        if (!Token) {
          addLocalCart(
            res?.product?.product_varient_skus?.[0],
            res?.product,
            1,
            res?.product?.[`for_${no_of_packed}`],
            "single"
          );
        } else {
          let tempPro = [];

          tempPro.push({
            product_id: res?.product?.id,
            product_sku_id: res?.product?.product_varient_skus?.[0]?.id,
            no_of_colors: 1,
            qty: 1,
            price: res?.product?.[`for_${no_of_packed}`],
          });

          if (presetData?.PresetPack?.id) {
            presetData?.cartDetail?.map((item, ind) => {
              let obj = {
                product_id: item?.product?.id,
                no_of_colors: item?.no_of_colors,
                qty: item?.qty,
                price: item?.product?.[`for_${no_of_packed}`],
              };

              if (item?.product_sku?.[0]?.id) {
                obj.product_sku_id = item?.product_sku?.[0]?.id;
              }

              tempPro.push(obj);
            });
          }

          const formData = new FormData();
          formData.append("type", 1);
          // formData.append("preset_pack_id", " ");
          formData.append("no_of_packs", no_pack);

          if (tempPro?.length > 0) {
            tempPro?.map((item, ind) => {
              formData.append(`cart_details[${ind}][qty]`, item?.qty);
              formData.append(
                `cart_details[${ind}][product_id]`,
                item?.product_id
              );

              if (item?.product_sku_id) {
                formData.append(
                  `cart_details[${ind}][product_sku_id]`,
                  item?.product_sku_id
                );
              }
              formData.append(`cart_details[${ind}][price]`, item?.price);
              formData.append(
                `cart_details[${ind}][no_of_colors]`,
                item?.no_of_colors
              );
            });
          }

          addToCartApi(formData)
            .unwrap()
            .then((resp) => {
              navigate("/dashboard/lets-start/build-pack");
              dispatch(saveLoader(false));
              setCartBtn(false);
              const temp = [];
              temp.push(viewId);
              setTick(temp);
              getCartApi();
              // console.log(res);
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setCartBtn(false);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
        setCartBtn(false);
      });
  };

  // CART LIST
  const getCartApi = (type) => {
    const formData = new FormData();
    formData.append("type", 2);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        if (!res?.cart_detail?.id) {
          navigate("/dashboard/lets-start/build-pack");
        } else {
          if (type == "delete") {
            moveToIndiviual(res?.cart_detail);
          } else {
            const data = res?.cart_detail?.cartDetail;
            let price_per_pack = res?.pack_prices;
            let discount = res?.cart_detail?.PresetPack?.discount;
            const temp = [];
            let tempArr = [];
            data?.map((item) => {
              temp?.push(item?.product?.id);
              setTick(temp);
            });

            if (res?.cart_detail?.id) {
              setPresetData(res?.cart_detail);
              let qtys = res?.cart_detail?.no_of_packs;
              let total_price_cart;
              setNo_of_pack(qtys);
              let price_obj = {};

              if (res?.cart_detail?.PresetPack?.id) {
                price_obj["25"] =
                  +price_per_pack?.["25"] -
                  (+price_per_pack?.["25"] * +discount) / 100;

                price_obj["50"] =
                  +price_per_pack?.["50"] -
                  (+price_per_pack?.["50"] * +discount) / 100;

                price_obj["100"] =
                  +price_per_pack?.["100"] -
                  (+price_per_pack?.["100"] * +discount) / 100;

                price_obj["150"] =
                  +price_per_pack?.["150"] -
                  (+price_per_pack?.["150"] * +discount) / 100;

                price_obj["250"] =
                  +price_per_pack?.["250"] -
                  (+price_per_pack?.["250"] * +discount) / 100;

                price_obj["500"] =
                  +price_per_pack?.["500"] -
                  (+price_per_pack?.["500"] * +discount) / 100;

                price_obj["1000"] =
                  +price_per_pack?.["1000"] -
                  (+price_per_pack?.["1000"] * +discount) / 100;

                setPriceList(price_obj);
              } else {
                setPriceList(res?.pack_prices);
              }

              setPriceLists({ ...res?.pack_prices, discount: +discount });

              let no_of_packed = 25;
              res?.cart_detail?.cartDetail?.map((item, ind) => {
                let qty_val = item?.product?.[`for_${qtys}`];
                let sum = +item?.qty * qtys;
                let obj = {
                  for_price: +qty_val,
                  pack: res?.cart_detail?.no_of_packs,
                  count: +item?.qty,
                  cart_id: item?.id,
                  no_of_colors: item?.no_of_colors,
                };

                if (+qtys >= 1 && +qtys <= 25) {
                  no_of_packed = 25;
                } else if (+qtys > 25 && +qtys <= 50) {
                  no_of_packed = 50;
                } else if (+qtys > 50 && +qtys <= 100) {
                  no_of_packed = 100;
                } else if (+qtys > 100 && +qtys <= 150) {
                  no_of_packed = 150;
                } else if (+qtys > 150 && +qtys <= 250) {
                  no_of_packed = 250;
                } else if (+qtys > 250 && +qtys <= 500) {
                  no_of_packed = 500;
                } else if (+qtys > 500 && +qtys <= 1000) {
                  no_of_packed = 1000;
                } else if (+qtys > 1000) {
                  no_of_packed = 1000;
                }

                if (res?.cart_detail?.PresetPack?.total_price) {
                  // if (cartData?.[item?.product?.id]?.pack) {
                  //   obj.discount_value =
                  //     price_obj[cartData?.[item?.product?.id]?.pack];
                  // } else {
                  //   obj.discount_value = price_obj[res?.cart_detail?.no_of_packs];
                  // }

                  obj.discount_value = price_obj[no_of_packed];
                } else {
                  obj.discount_value = res?.cart_detail?.price_per_pack;
                }

                if (qty_val) {
                  obj.for_price = +qty_val;
                } else {
                  obj.for_price = +qtys;
                }

                if (+sum >= 1 && +sum <= 25) {
                  obj.packs_price = +item?.product?.for_25;
                  total_price_cart = +item?.product?.for_25;
                } else if (+sum > 25 && +sum <= 50) {
                  obj.packs_price = +item?.product?.for_50;
                  total_price_cart = +item?.product?.for_50;
                } else if (+sum > 50 && +sum <= 100) {
                  obj.packs_price = +item?.product?.for_100;
                  total_price_cart = +item?.product?.for_100;
                } else if (+sum > 100 && +sum <= 150) {
                  obj.packs_price = +item?.product?.for_150;
                  total_price_cart = +item?.product?.for_150;
                } else if (+sum > 150 && +sum <= 250) {
                  obj.packs_price = +item?.product?.for_250;
                  total_price_cart = +item?.product?.for_250;
                } else if (+sum > 250 && +sum <= 500) {
                  obj.packs_price = +item?.product?.for_500;
                  total_price_cart = +item?.product?.for_500;
                } else if (+sum > 500 && +sum <= 1000) {
                  obj.packs_price = +item?.product?.for_1000;
                  total_price_cart = +item?.product?.for_1000;
                } else if (+sum > 1000) {
                  obj.packs_price = +item?.product?.for_1000;
                  total_price_cart = +item?.product?.for_1000;
                }

                // DEFAULT SKU FIND
                let findSku =
                  res?.cart_detail?.PresetPack?.preset_product?.find(
                    (i) => i?.product?.id == item?.product?.id
                  );

                // tempArr.push({
                //   ...item,
                //   total_price: total_price_cart,
                //   default_sku: findSku?.product_sku,
                // });

                let cart_detail = {
                  ...item,
                  total_price: total_price_cart,
                  default_sku: findSku?.product_sku,
                };

                if (item?.product_sku?.id) {
                  let finder = item?.product?.colorImages?.find(
                    (i) => i?.color_id == item?.product_sku?.color_id
                  );

                  if (finder?.id) {
                    cart_detail.selected_image_url = finder?.image_url;
                  } else {
                    cart_detail.selected_image_url =
                      item?.product?.defaultImage;
                  }
                } else {
                  cart_detail.selected_image_url = item?.product?.defaultImage;
                }

                tempArr.push(cart_detail);

                // console.log("tempArr", tempArr);

                setCartData((val) => ({
                  ...val,
                  [item?.product?.id]: obj,
                }));
              });
              setPack(res?.cart_detail?.no_of_packs);
              setTotalPrice(res?.cart_detail?.total_amount);
              setTotalPerPrice(res?.cart_detail?.price_per_pack);
              setCartList(tempArr);
            } else {
              setCartList([]);
              setPriceList({});
            }
            dispatch(saveLoader(false));
            setDeleteBtn(false);
          }
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  // console.log("priceList", priceList);

  // PRODUCT VIEW
  const getProductViews = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        let colorFinder = res?.product?.colors?.find(
          (i) => i?.id == item?.product_sku?.color_id
        );
        let findIndex = res?.product?.colors?.findIndex(
          (i) => i?.id == item?.product_sku?.color_id
        );

        // setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product?.id]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product?.id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }

        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };

        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );
            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.name);
          setSelectColorId(colorFinder?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(res?.product?.colors?.[0]?.name);
          setSelectColorId(res?.product?.colors?.[0]?.id);
          setBigImg(0);
        }

        // setStyle(ima)
        // console.log("temps", temps);
        let finalArr = [
          {
            ...res?.product,
            images: image,
          },
        ];
        setViewList(finalArr);

        if (finalArr.length > 0) {
          showPopupHander();
        }

        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        let colorFinder = res?.product?.colors?.find(
          (i) => i?.id == item?.product_sku?.color_id
        );
        let findIndex = res?.product?.colors?.findIndex(
          (i) => i?.id == item?.product_sku?.color_id
        );

        // setUserCartID(cartID);
        setListPrice(res?.product);

        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product?.id]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product?.id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }

        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };

        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const image = [];
        if (item?.default_sku?.id) {
          setSelectedColorId(colorFinder?.name);
          setSelectColorId(colorFinder?.id);
          setCodeList([item?.default_sku]);
          setBigImg(0);

          let find_co_img = res?.product?.colorImages?.find(
            (i) => i?.color_id == item?.default_sku?.color_id
          );

          let obj = {
            ...find_co_img,
            color: item?.default_sku?.color,
          };

          image.push(obj);
        } else {
          if (item?.product_sku?.status == 1) {
            let findIndexer = res?.product?.colors?.findIndex(
              (i) => i?.id == item?.product_sku?.color_id
            );
            setSelectedColorId(item?.product_sku?.color?.name);
            setSelectColorId(item?.product_sku?.color?.id);
            setBigImg(findIndexer);
          } else {
            setSelectedColorId(res?.product?.colors?.[0]?.name);
            setSelectColorId(res?.product?.colors?.[0]?.id);
            setBigImg(0);
          }
          setCodeList(res?.product?.product_varient_skus);
          temp?.map((items, ind) => {
            items?.colorImages?.map((color) => {
              let color_id_finder = items?.colors?.find(
                (i) => i?.id == color?.color_id
              );
              image.push({
                ...color,
                color: color_id_finder,
              });
            });
          });
          // const images = [];
          // temp?.map((item, ind) => {
          //   item?.colorImages?.map((color) => {
          //     let color_id_finder = item?.colors?.find(
          //       (i) => i?.id == color?.color_id
          //     );

          //     images.push({
          //       ...color,
          //       color: color_id_finder,
          //     });
          //   });
          // });
          // console.log(images, "images");

          // const uniqueColors = [];
          // temp?.map((item, ind) => {
          //   item?.product_varient_skus?.map((color) => {
          //     let finderImage = images?.find(
          //       (i) => i?.color_id == color?.color_id
          //     );

          //     uniqueColors.push(finderImage);
          //   });
          // });
          // console.log(uniqueColors, "fff");

          // const uniqueId = new Map(uniqueColors.map((c) => [c.color_id, c]));
          // const image = [...uniqueId.values()];
          // let colorFinder = image?.find(
          //   (i) => i?.color_id == item?.product_sku?.color_id
          // );
          // let findIndex = image?.findIndex(
          //   (i) => i?.color_id == item?.product_sku?.color_id
          // );

          // console.log(image, "image");
          // if (item?.product_sku?.status == 1) {
          //   setSelectedColorId(colorFinder?.color?.name);
          //   setSelectColorId(colorFinder?.color?.id);
          //   setBigImg(findIndex);
          // } else {
          //   setSelectedColorId(image?.[0]?.color?.name);
          //   setSelectColorId(image?.[0]?.color?.id);
          //   setBigImg(0);
          // }
        }
        // console.log(image, "image");
        // setStyle(ima)
        let finalArr = [
          {
            ...res?.product,
            images: image,
          },
        ];
        setViewList(finalArr);

        if (finalArr.length > 0) {
          showPopupHander();
        }

        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };
  // console.log(viewList, "view");

  // DELETE CART
  const deleteCart = (id, pro_id) => {
    if (!Token) {
      deleteLocalCart(pro_id);
    } else {
      const formData = new FormData();
      formData.append("user_cart_detail_id", id);
      // console.log("pro_id", pro_id);
      dispatch(saveLoader(true));
      setDeleteBtn(true);
      deleteApi(formData)
        .unwrap()
        .then((res) => {
          // console.log(res?.navigations);
          // setCategoryList(res?.navigations);

          // dispatch(saveLoader(false));
          // setDeleteBtn(false);
          let obj = { ...cartData };
          delete obj[pro_id];
          setCartData(obj);
          getCartApi("delete");
          let temp = [...tick];
          let inx = temp.indexOf(pro_id);
          temp.splice(inx, 1);
          setTick(temp);
          setShowPopup(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setDeleteBtn(false);
          console.log("err", err);
        });
    }
  };

  const moveToIndiviual = (data) => {
    let qtys = data?.no_of_packs;
    let no_of_packed = 25;

    if (+qtys >= 1 && +qtys <= 25) {
      no_of_packed = 25;
    } else if (+qtys > 25 && +qtys <= 50) {
      no_of_packed = 50;
    } else if (+qtys > 50 && +qtys <= 100) {
      no_of_packed = 100;
    } else if (+qtys > 100 && +qtys <= 150) {
      no_of_packed = 150;
    } else if (+qtys > 150 && +qtys <= 250) {
      no_of_packed = 250;
    } else if (+qtys > 250 && +qtys <= 500) {
      no_of_packed = 500;
    } else if (+qtys > 500 && +qtys <= 1000) {
      no_of_packed = 1000;
    } else if (+qtys > 1000) {
      no_of_packed = 1000;
    }

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("no_of_packs", data?.no_of_packs);

    data?.cartDetail?.map((item, ind) => {
      formData.append(`cart_details[${ind}][qty]`, item?.qty);
      formData.append(`cart_details[${ind}][product_id]`, item?.product?.id);

      if (item?.product_sku?.status == 1) {
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product_sku?.id
        );
      } else {
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product?.productSku?.[0]?.id
        );
      }

      formData.append(
        `cart_details[${ind}][price]`,
        item?.product[`for_${no_of_packed}`]
      );
      formData.append(`cart_details[${ind}][no_of_colors]`, item?.no_of_colors);
    });

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        navigate("/dashboard/lets-start/build-pack");
        dispatch(saveLoader(false));
        setCartBtn(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  const product = () => {
    const formData = new FormData();
    ProductListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res?.navigations);
        setCategoryList(res?.navigations);
        let temp = [];

        res?.navigations?.map((item, ind) => {
          item?.products?.map((items, inds) => {
            temp.push(items);
          });
        });

        const ids = temp.map(({ id }) => id);
        const filtered = temp.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );

        setAllProducts(filtered);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ADD TO CART
  const varientsNew = (id, qty, packsPrice, colorID, noOfColors, type) => {
    let codeFinder = skuCodeList?.find((i) => i?.color_id == colorID);

    if (codeFinder?.id) {
      let pack_value = cartData?.[id]?.pack;

      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      if (!Token) {
        updateLocalCart(id, qty, packs_val, codeFinder, noOfColors, type);
      } else {
        let count_value = cartData?.[id]?.count
          ? +cartData?.[id]?.count
          : +cartData?.[id]?.count_value;

        let no_of_colors = noOfColors
          ? noOfColors
          : cartData?.[id]?.no_of_colors
          ? +cartData?.[id]?.no_of_colors
          : 1;

        let temp = [];

        let no_of_packed = 25;

        if (+pack_value >= 1 && +pack_value <= 25) {
          no_of_packed = 25;
        } else if (+pack_value > 25 && +pack_value <= 50) {
          no_of_packed = 50;
        } else if (+pack_value > 50 && +pack_value <= 100) {
          no_of_packed = 100;
        } else if (+pack_value > 100 && +pack_value <= 150) {
          no_of_packed = 150;
        } else if (+pack_value > 150 && +pack_value <= 250) {
          no_of_packed = 250;
        } else if (+pack_value > 250 && +pack_value <= 500) {
          no_of_packed = 500;
        } else if (+pack_value > 500 && +pack_value <= 1000) {
          no_of_packed = 1000;
        } else if (+pack_value > 1000) {
          no_of_packed = 1000;
        }

        temp.push({
          product_id: id,
          product_sku_id: codeFinder?.id,
          no_of_colors: no_of_colors,
          qty: qty ? qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });

        if (presetData?.PresetPack?.id) {
          presetData?.cartDetail?.map((item, ind) => {
            if (item?.product?.id !== id) {
              let obj = {
                product_id: item?.product?.id,
                no_of_colors: item?.no_of_colors,
                qty: item?.qty,
                price: packsPrice
                  ? packsPrice
                  : item?.product?.[`for_${no_of_packed}`],
              };

              if (item?.product_sku?.status == 1) {
                obj.product_sku_id = item?.product_sku?.id;
              }
              // else {
              //   obj.product_sku_id =
              //     item?.product?.productSku?.[
              //       item?.product?.productSku?.length - 1
              //     ]?.id;
              // }

              temp.push(obj);
            }
          });
        }

        const formData = new FormData();
        formData.append("type", 2);
        formData.append("no_of_packs", pack_value);
        formData.append("preset_pack_id", presetData?.PresetPack?.id);

        if (temp?.length > 0) {
          temp?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );
            if (item?.product_sku_id) {
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
            }

            formData.append(`cart_details[${ind}][price]`, item?.price);
            formData.append(
              `cart_details[${ind}][no_of_colors]`,
              item?.no_of_colors
            );
          });
        }

        // console.log("productId", productId);

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            getCartApi();
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };

  // ADD CART
  const getAddToCarts = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: colorcount,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    if (presetData?.PresetPack?.id) {
      presetData?.cartDetail?.map((item, ind) => {
        if (item?.product?.id !== productId) {
          let obj = {
            product_id: item?.product?.id,
            no_of_colors: item?.no_of_colors,
            qty: item?.qty,
            price: item?.product?.for_25,
          };

          if (item?.product_sku?.[0]?.id) {
            obj.product_sku_id = item?.product_sku?.[0]?.id;
          }

          temp.push(obj);
        }
      });
    }

    const formData = new FormData();
    formData.append("type", 2);
    formData.append("no_of_packs", pack_value);
    // formData.append("preset_pack_id", " ");

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        if (item?.product_sku_id) {
          formData.append(
            `cart_details[${ind}][product_sku_id]`,
            item?.product_sku_id
          );
        }

        formData.append(`cart_details[${ind}][price]`, item?.price);
        formData.append(
          `cart_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );
      });
    }

    // console.log("productId", productId);

    // addToCartApi(formData)
    //   .unwrap()
    //   .then((res) => {
    //     dispatch(saveLoader(false));
    //     setCartBtn(false);
    //     // if (!qty) {
    //     //   showPopupHander();
    //     // }
    //     // const temp = [];
    //     // temp.push(viewId);
    //     // setTick(temp);
    //     getCartApi();
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     dispatch(saveLoader(false));
    //     setCartBtn(false);
    //     console.log(err);
    //   });
  };

  // ADD CART FOR INDVIUAL
  const varients = (id, qty, packsPrice) => {
    let temp = "";
    if (selectedColorId) {
      temp = `/${selectedColorId}`;
    }

    if (size) {
      temp = temp + `/${size}`;
    }

    if (material) {
      temp = temp + `/${material}`;
    }

    if (style) {
      temp = temp + `/${style}`;
    }

    let codeFinder = skuCodeList?.find((i) => i?.variant_name?.includes(temp));
    // console.log("codeFinder", codeFinder);
    // setCode(skuCode)
    if (codeFinder?.id) {
      dispatch(saveLoader(true));
      setCartBtn(true);
      if (!Token) {
        addLocalCart(codeFinder, id, qty, packsPrice, "single");
      } else {
        getAddToCart(codeFinder?.id, id, qty, packsPrice);
      }
    } else {
      dispatch(saveLoader(true));
      setCartBtn(true);
      if (!Token) {
        addLocalCart(skuCodeList?.[0], id, qty, packsPrice, "single");
      } else {
        getAddToCart(skuCodeList?.[0]?.id, id, qty, packsPrice);
      }
    }
  };

  // ADD CART FOR INDVIUAL
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: colorcount,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    let no_of_packed = 25;

    if (+pack_value >= 1 && +pack_value <= 25) {
      no_of_packed = 25;
    } else if (+pack_value > 25 && +pack_value <= 50) {
      no_of_packed = 50;
    } else if (+pack_value > 50 && +pack_value <= 100) {
      no_of_packed = 100;
    } else if (+pack_value > 100 && +pack_value <= 150) {
      no_of_packed = 150;
    } else if (+pack_value > 150 && +pack_value <= 250) {
      no_of_packed = 250;
    } else if (+pack_value > 250 && +pack_value <= 500) {
      no_of_packed = 500;
    } else if (+pack_value > 500 && +pack_value <= 1000) {
      no_of_packed = 1000;
    } else if (+pack_value > 1000) {
      no_of_packed = 1000;
    }

    if (presetData?.PresetPack?.id) {
      presetData?.cartDetail?.map((item, ind) => {
        if (item?.product?.id !== productId) {
          // console.log("item", item);
          let obj = {
            product_id: item?.product?.id,
            no_of_colors: item?.no_of_colors,
            qty: item?.qty,
            price: item?.product?.[`for_${no_of_packed}`],
          };

          if (item?.product_sku?.status == 1) {
            obj.product_sku_id = item?.product_sku?.id;
          } else {
            obj.product_sku_id = item?.product?.productSku?.[0]?.id;
          }

          temp.push(obj);
        }
      });
    }

    // console.log("temp", temp);

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("no_of_packs", pack_value);
    // formData.append("preset_pack_id", " ");

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        if (item?.product_sku_id) {
          formData.append(
            `cart_details[${ind}][product_sku_id]`,
            item?.product_sku_id
          );
        }

        formData.append(`cart_details[${ind}][price]`, item?.price);
        formData.append(
          `cart_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        navigate("/dashboard/lets-start/build-pack");
        dispatch(saveLoader(false));
        setCartBtn(false);
        // if (!qty) {
        //   showPopupHander();
        // }
        // const temp = [];
        // temp.push(viewId);
        // setTick(temp);
        // getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  //PRODUCT PRICE LIST
  const productPrice = () => {
    const formData = new FormData();
    if (selectedIds?.length > 0) {
      selectedIds?.map((item, ind) => {
        formData.append(`product_ids[${ind}]`, item);
      });
    }
    productPriceApi(formData)
      .unwrap()
      .then((res) => {
        const price = res?.total_price;
        setPacksPrice(price?.[pack]);

        // setPriceList(price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculations = (qty, id, packs, type) => {
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.discount_value = +cartData?.[id]?.discount_value;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);

    if (+cartData?.[id]?.cart_id) {
      obj.cart_id = +cartData?.[id]?.cart_id;
      if (!Token) {
        addLocalCart("", "", qty_val, "", "qty", id);
      } else {
        varients(
          id,
          qty_val,
          obj?.packs_price ? obj?.packs_price : obj.for_price
        );
      }
    }
    // }
  };

  const customQty = (no_of_packs) => {
    if (!Token) {
      customQtyLocal(no_of_packs);
    } else {
      const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

      if (no_of_pack?.length == 0) {
        setNoOfPackErr(true);
      } else {
        let formdata = new FormData();
        formdata.append("no_of_packs", no_of_packs ? no_of_packs : noOfPack);
        packUpdateApi(formdata)
          .unwrap()
          .then((res) => {
            setNoOfPack("");
            setNoOfPackErr(false);
            getCartApi();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  // GET DATABASE
  const getDB = (skuId) => {
    var open = indexedDB.open("CartDB");
    open.onsuccess = function () {
      let db = open.result;
      setDataBase(db);
      console.log("success!");
      let tx = db.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("preset_pack");
      data.onsuccess = (eve) => {
        // console.log("eve.target.result", eve.target.result);
        let res = eve.target.result;

        if (!res?.no_of_packs) {
          navigate("/dashboard/lets-start/build-pack");
        } else {
          const data = res?.cartDetail;
          let price_per_pack = res?.pack_prices;
          let discount = res?.preset_pack?.discount;
          const temp = [];
          let tempArr = [];
          data?.map((item) => {
            temp?.push(item?.product?.id);
            setTick(temp);
          });

          setPresetData(res);
          let qtys = res?.no_of_packs;
          let total_price_cart;
          setNo_of_pack(qtys);
          let price_obj = {};

          if (res?.preset_pack?.id) {
            price_obj["25"] =
              +price_per_pack?.["25"] -
              (+price_per_pack?.["25"] * +discount) / 100;

            price_obj["50"] =
              +price_per_pack?.["50"] -
              (+price_per_pack?.["50"] * +discount) / 100;

            price_obj["100"] =
              +price_per_pack?.["100"] -
              (+price_per_pack?.["100"] * +discount) / 100;

            price_obj["150"] =
              +price_per_pack?.["150"] -
              (+price_per_pack?.["150"] * +discount) / 100;

            price_obj["250"] =
              +price_per_pack?.["250"] -
              (+price_per_pack?.["250"] * +discount) / 100;

            price_obj["500"] =
              +price_per_pack?.["500"] -
              (+price_per_pack?.["500"] * +discount) / 100;

            price_obj["1000"] =
              +price_per_pack?.["1000"] -
              (+price_per_pack?.["1000"] * +discount) / 100;

            setPriceList(price_obj);
          } else {
            setPriceList(res?.pack_prices);
          }

          setPriceLists({ ...res?.pack_prices, discount: +discount });

          let no_of_packed = 25;
          res?.cartDetail?.map((item, ind) => {
            let qty_val = item?.product?.[`for_${qtys}`];
            let sum = +item?.qty * qtys;
            let obj = {
              for_price: +qty_val,
              pack: res?.no_of_packs,
              count: +item?.qty,
              cart_id: item?.id,
              no_of_colors: item?.no_of_colors,
              cart_id: 10,
            };

            if (+qtys >= 1 && +qtys <= 25) {
              no_of_packed = 25;
            } else if (+qtys > 25 && +qtys <= 50) {
              no_of_packed = 50;
            } else if (+qtys > 50 && +qtys <= 100) {
              no_of_packed = 100;
            } else if (+qtys > 100 && +qtys <= 150) {
              no_of_packed = 150;
            } else if (+qtys > 150 && +qtys <= 250) {
              no_of_packed = 250;
            } else if (+qtys > 250 && +qtys <= 500) {
              no_of_packed = 500;
            } else if (+qtys > 500 && +qtys <= 1000) {
              no_of_packed = 1000;
            } else if (+qtys > 1000) {
              no_of_packed = 1000;
            }

            if (res?.preset_pack?.total_price) {
              obj.discount_value = price_obj[no_of_packed];
            } else {
              obj.discount_value = res?.price_per_pack;
            }

            if (qty_val) {
              obj.for_price = +qty_val;
            } else {
              obj.for_price = +qtys;
            }

            if (+sum >= 1 && +sum <= 25) {
              obj.packs_price = +item?.product?.for_25;
              total_price_cart = +item?.product?.for_25;
            } else if (+sum > 25 && +sum <= 50) {
              obj.packs_price = +item?.product?.for_50;
              total_price_cart = +item?.product?.for_50;
            } else if (+sum > 50 && +sum <= 100) {
              obj.packs_price = +item?.product?.for_100;
              total_price_cart = +item?.product?.for_100;
            } else if (+sum > 100 && +sum <= 150) {
              obj.packs_price = +item?.product?.for_150;
              total_price_cart = +item?.product?.for_150;
            } else if (+sum > 150 && +sum <= 250) {
              obj.packs_price = +item?.product?.for_250;
              total_price_cart = +item?.product?.for_250;
            } else if (+sum > 250 && +sum <= 500) {
              obj.packs_price = +item?.product?.for_500;
              total_price_cart = +item?.product?.for_500;
            } else if (+sum > 500 && +sum <= 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            } else if (+sum > 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            }

            let cart_detail = {
              ...item,
              total_price: total_price_cart,
              // default_sku: findSku?.product_sku,
            };

            if (item?.product_sku?.id) {
              let finder = item?.product?.colorImages?.find(
                (i) => i?.color_id == item?.product_sku?.color_id
              );

              if (finder?.id) {
                cart_detail.selected_image_url = finder?.image_url;
              } else {
                cart_detail.selected_image_url = item?.product?.defaultImage;
              }
            } else {
              cart_detail.selected_image_url = item?.product?.defaultImage;
            }

            tempArr.push(cart_detail);

            setCartData((val) => ({
              ...val,
              [item?.product?.id]: obj,
            }));
          });
          setPack(res?.no_of_packs);
          setTotalPrice(res?.total_amount);
          setTotalPerPrice(res?.price_per_pack);
          setCartList(tempArr);
        }

        data.onerror = function () {
          console.log(data.error);
        };
      };

      open.onerror = function () {
        console.log(open.error);
      };
    };
  };

  // DELETE LOCAL CART
  const deleteLocalCart = (id) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("preset_pack");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;

      // DELETE CART
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);
      arr.splice(index, 1);

      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      const temp = [];
      let pack_value = obj?.no_of_packs;

      let packs = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      arr?.map((item, ind) => {
        temp.push(item?.product?.id);
        packs[25] = packs[25] += item?.product?.for_25;
        packs[50] = packs[50] += item?.product?.for_50;
        packs[100] = packs[100] += item?.product?.for_100;
        packs[150] = packs[150] += item?.product?.for_150;
        packs[250] = packs[250] += item?.product?.for_250;
        packs[500] = packs[500] += item?.product?.for_500;
        packs[1000] = packs[1000] += item?.product?.for_1000;
      });

      let final = {
        ...obj,
        cartDetail: arr,
        pack_prices: packs,
      };

      let request = stored.put(final, "preset_pack");

      request.onsuccess = function () {
        console.log("success!");
        let obj = { ...cartData };
        delete obj[id];
        setCartData(obj);
        setTick(temp);
        setShowPopup(false);
        // getDB();
        addLocalCart();
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  // PACK QTY CHANGE AND CUSTOM QTY CHANGE
  const customQtyLocal = (no_of_packs) => {
    const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

    if (no_of_pack?.length == 0) {
      setNoOfPackErr(true);
    } else {
      let tx = dataBase.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("preset_pack");
      data.onsuccess = (eve) => {
        let txs = dataBase.transaction("CartTable", "readwrite");
        let stored = txs.objectStore("CartTable");
        let cart_data = eve.target.result;
        let obj = { ...cart_data };
        let result = obj?.pack_prices;
        let discount = obj?.preset_pack?.discount;

        let qtyPack;
        let pack_no = 25;

        if (+no_of_pack >= 1 && +no_of_pack <= 25) {
          qtyPack = +result?.[25];
          pack_no = 25;
        } else if (+no_of_pack > 25 && +no_of_pack <= 50) {
          qtyPack = +result?.[50];
          pack_no = 50;
        } else if (+no_of_pack > 50 && +no_of_pack <= 100) {
          qtyPack = +result?.[100];
          pack_no = 100;
        } else if (+no_of_pack > 100 && +no_of_pack <= 150) {
          qtyPack = +result?.[150];
          pack_no = 150;
        } else if (+no_of_pack > 150 && +no_of_pack <= 250) {
          qtyPack = +result?.[250];
          pack_no = 250;
        } else if (+no_of_pack > 250 && +no_of_pack <= 500) {
          qtyPack = +result?.[500];
          pack_no = 500;
        } else if (+no_of_pack > 500 && +no_of_pack <= 1000) {
          qtyPack = +result?.[1000];
          pack_no = 1000;
        } else if (+no_of_pack > 1000) {
          qtyPack = +result?.[1000];
          pack_no = 1000;
        }

        let price_per_pack = +qtyPack - (+qtyPack * +discount) / 100;

        let total_amount = +price_per_pack * +no_of_pack;

        let final = {
          ...obj,
          no_of_packs: +no_of_pack,
          price_per_pack: Number(price_per_pack).toFixed(2),
          total_amount: Number(total_amount).toFixed(2),
        };

        let request = stored.put(final, "preset_pack");

        request.onsuccess = function () {
          getDB();
          setNoOfPack("");
          setNoOfPackErr(false);
        };

        request.onerror = function () {
          console.log(request.error);
        };
      };
    }
  };

  // ADD LOCAL CART
  const addLocalCart = (skuId, productId, qty, packsPrice, type, id) => {
    let txs = dataBase.transaction("CartTable", "readonly");
    let stored = txs.objectStore("CartTable");

    let data = stored.get("preset_pack");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;
      let tx = dataBase.transaction("CartTable", "readwrite");
      let store = tx.objectStore("CartTable");

      let pack_value = +cartData?.[productId]?.pack
        ? cartData?.[productId]?.pack
        : 25;

      let packs_val = +cartData?.[productId]?.packs_price
        ? +cartData?.[productId]?.packs_price
        : +cartData?.[productId]?.for_price;

      let count_value = cartData?.[productId]?.count
        ? +cartData?.[productId]?.count
        : +cartData?.[productId]?.count_value;

      let no_of_colors = cartData?.[productId]?.no_of_colors
        ? +cartData?.[productId]?.no_of_colors
        : 1;

      let temp = [];
      let pro_temp = [];

      let product_ = allProducts?.find((i) => i?.id == productId);

      if (type == "single") {
        temp.push({
          no_of_colors: 1,
          price: packsPrice,
          qty: qty,
          product_sku_id: skuId?.id,
          product_sku: skuId,
          product: product_,
          product_id: product_?.id,
          cart_id: 10,
        });
      }

      cart_data?.cartDetail?.map((item, ind) => {
        if (item?.product_id !== productId?.id) {
          let store_object = {
            ...item,
            cart_id: 10,
          };

          if (type == "qty") {
            if (item?.product_id == id) {
              store_object["qty"] = qty;
            }
          }
          temp.push(store_object);
        }
      });

      let prices_pack = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      temp.map((item, ind) => {
        let finder = item?.product;
        let finder_sku = item?.product_sku;
        let total_value;

        let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
        let quantity_1 = +item?.qty;

        pack_sizes.map((price) => {
          let quantity = +quantity_1 * +price;

          if (+quantity >= 1 && +quantity <= 25) {
            prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
          } else if (+quantity > 25 && +quantity <= 50) {
            prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
          } else if (+quantity > 50 && +quantity <= 100) {
            prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
          } else if (+quantity > 100 && +quantity <= 150) {
            prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
          } else if (+quantity > 150 && +quantity <= 250) {
            prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
          } else if (+quantity > 250 && +quantity <= 500) {
            prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
          } else if (+quantity > 500 && +quantity <= 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          } else if (+quantity > 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          }
        });

        let obj = {
          ...item,
          product: finder,
          product_sku: finder_sku,
          product_id: item?.product_id,
          product_sku_id: item?.product_sku_id,
          total_price: +packsPrice ? +packsPrice : +packs_val,
        };
        pro_temp.push(obj);
      });

      let no_of_packed = 25;

      if (+no_pack >= 1 && +no_pack <= 25) {
        no_of_packed = 25;
      } else if (+no_pack > 25 && +no_pack <= 50) {
        no_of_packed = 50;
      } else if (+no_pack > 50 && +no_pack <= 100) {
        no_of_packed = 100;
      } else if (+no_pack > 100 && +no_pack <= 150) {
        no_of_packed = 150;
      } else if (+no_pack > 150 && +no_pack <= 250) {
        no_of_packed = 250;
      } else if (+no_pack > 250 && +no_pack <= 500) {
        no_of_packed = 500;
      } else if (+no_pack > 500 && +no_pack <= 1000) {
        no_of_packed = 1000;
      } else if (+no_pack > 1000) {
        no_of_packed = 1000;
      }

      let final = {
        no_of_packs: +no_pack,
        price_per_pack: Number(+prices_pack?.[`${no_of_packed}`]).toFixed(2),
        total_amount: Number(
          +prices_pack?.[`${no_of_packed}`] * +no_pack
        ).toFixed(2),
        cartDetail: pro_temp,
        pack_prices: prices_pack,
      };

      let request = store.put(final, "individual");

      request.onsuccess = function () {
        let txDb = dataBase.transaction("CartTable", "readwrite");
        let storedObject = txDb.objectStore("CartTable");
        let requests = storedObject.put({}, "preset_pack");

        requests.onsuccess = function () {
          console.log("success!");
          navigate("/dashboard/lets-start/build-pack");
        };

        requests.onerror = function () {
          console.log(request.error);
        };
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  // UPDATE LOCAL CART
  const updateLocalCart = (id, qty, packsPrice, colors, noOfColors, type) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("preset_pack");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);

      // EDIT
      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      arr[index] = {
        ...arr[index],
      };

      if (type == "logo") {
        arr[index].no_of_colors = noOfColors;
      }

      if (type == "color") {
        arr[index].product_sku = colors;
        arr[index].product_sku_id = colors?.id;
      }

      let final = {
        ...obj,
        cartDetail: arr,
      };

      let request = stored.put(final, "preset_pack");

      request.onsuccess = function () {
        console.log("success!");
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };
  };

  useEffect(() => {
    product();
    if (!Token) {
      getDB();
    } else {
      getCartApi();
    }
  }, []);

  return (
    <div className="product-screen d-flex flex-column flex-md-row mt-4 w-100">
      <PresetFullProduct
        tick={tick}
        categoryList={categoryList}
        showPopupHander={showPopupHander}
        setViewId={setViewId}
        setTick={setTick}
        onClickProduct={onClickProduct}
        getAddToCart={getAddToCart}
        getProductView={getProductView}
        cartData={cartData}
        deleteCart={deleteCart}
        userCartID={userCartID}
      />
      {cortList?.length > 0 && (
        <PresetProductCart
          pack="build"
          tick={tick}
          categoryList={categoryList}
          cortList={cortList}
          priceList={priceList}
          priceLists={priceLists}
          setShowPopup={showPopupHander}
          deleteCart={deleteCart}
          getProductView={getProductView}
          packs={pack}
          totalPrice={totalPrice}
          totalPerPrice={totalPerPrice}
          deleteBtn={deleteBtn}
          customQty={customQty}
          noOfPack={noOfPack}
          setNoOfPack={setNoOfPack}
          noOfPackErr={noOfPackErr}
          setShowOption={setShowOption}
          showoption={showoption}
        />
      )}
      {showPopup && (
        <PresetPopupComponent
          type={"induvial"}
          varients={varients}
          method={"start"}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          showPopupHander={showPopupHander}
          viewList={viewList}
          imagesid={imagesid}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          count={count}
          setCount={setCount}
          productPrice={productPrice}
          setTick={setTick}
          packsPrice={packsPrice}
          getAddToCart={getAddToCart}
          setColorCount={setColorCount}
          colorcount={colorcount}
          bigImg={bigImg}
          setBigImg={setBigImg}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          pack={pack}
          calculations={calculations}
          setCartData={setCartData}
          cartData={cartData}
          userCartID={userCartID}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          varientsNew={varientsNew}
        />
      )}
      {tick?.length > 0 && (
        <button className="cust-btn d-flex ac-jc d-xl-none mobile-cart">
          <ShoppingBagIcon />
        </button>
      )}
    </div>
  );
};

export default PresetPackProductScreen;
